<template>
  <!-- edit profile dialog -->
  <v-dialog
    v-if="data"
    v-model="isOpen"
    max-width="650px"
    @click:outside="$emit('update:is-dialog-open',false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5 mb-5">
        <span>{{ t('Delete Academy') }}</span>
        <span>&nbsp;</span>
        <span class="error--text">{{ data.title }}</span>
        <span>?</span>
      </v-card-title>

      <v-card-text class="text-center mt-n2">
        {{ t('Are you sure you wish to delete the selected academy?') }}
      </v-card-text>

      <v-card-text class="text-center mt-n2">
        {{ t('This action cannot be undone!') }}
      </v-card-text>

      <v-card-text class="mt-5">
        <v-col
          cols="12"
          class="d-flex justify-center mt-3"
        >
          <v-btn
            color="error"
            class="me-3"
            type="submit"
            :loading="loading"
            @click="handleSubmit"
          >
            {{ t('Delete') }}
          </v-btn>

          <v-btn
            outlined
            color="secondary"
            :disabled="loading"
            @click.prevent="isOpen = false"
          >
            {{ t('Discard') }}
          </v-btn>
        </v-col>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import { computed, getCurrentInstance } from '@vue/composition-api'

export default {
  props: {
    deleting: {
      type: Boolean,
      required: true,
      default: false,
    },
    isDialogOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const { t } = useUtils()
    const vm = getCurrentInstance().proxy

    const isOpen = computed({
      get: () => props.isDialogOpen,
      set: val => emit('update:is-dialog-open', val),
    })

    const loading = computed(() => props.deleting)

    const handleSubmit = () => {
      vm.$store
        .dispatch('academies/destroy', props.data.id)
        .then(res => {
          if (res) isOpen.value = false
        })
        .catch(err => {
          console.error(err)
        })
    }

    return {
      t,

      isOpen,

      loading,
      handleSubmit,
    }
  },
}
</script>
