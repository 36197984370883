import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useAcademyList() {
  const vm = getCurrentInstance().proxy

  const academyListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'SORT', value: 'sort', width: '10%' },
    { text: 'TITLE', value: 'title', width: '60%' },
    { text: 'VIDEOS', value: 'videosCount', width: '10%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const academies = computed({
    get: () => vm.$store.getters['academies/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['academies/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['academies/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['academies/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['academies/options'],
    set: val => vm.$store.dispatch('academies/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['academies/search'],
    set: val => vm.$store.dispatch('academies/setSearch', val),
  })
  const totalAcademyListTable = computed({
    get: () => vm.$store.getters['academies/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('academies/fetchItems')
  const setOptions = val => vm.$store.dispatch('academies/setOptions', val)
  const setFilters = val => vm.$store.dispatch('academies/setFilters', val)
  const handleReorder = val => vm.$store.dispatch('academies/order', val)

  const init = () => {
    fetchItems()
  }

  const destroy = () => {}

  return {
    academyListTable,
    tableColumns,

    totalAcademyListTable,
    loading,

    fetchItems,

    meta,
    search,
    academies,
    options,
    filters,

    setOptions,
    setFilters,
    handleReorder,

    init,
    destroy,
  }
}
