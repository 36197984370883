import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,[_vm._v(" Search & Filter ")]),_c(VCol,{staticClass:"pb-0",attrs:{"align":"end"}},[(_vm.$can('create', 'Academy'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","elevation":"4","color":"primary","to":{ name: 'academy-create' }}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,4248260112)},[_c('span',[_vm._v("Add New Academy")])]):_vm._e()],1)],1)],1),_c('vuex-table',{directives:[{name:"sortable-data-table",rawName:"v-sortable-data-table"}],attrs:{"headers":_vm.tableColumns,"items":_vm.academies,"options":_vm.options,"search":_vm.search,"meta":_vm.meta,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      },"item-key":"id","item-class":_vm.renderRowClass},on:{"update:search":function($event){_vm.search=$event},"update:meta":function($event){_vm.meta=$event},"sorted":_vm.handleOrder,"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.thumbUrl ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.thumbUrl ? '' : 'primary',"size":"32"}},[(item.thumbUrl)?_c(VImg,{attrs:{"src":item.thumbUrl}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.title)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'academy-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(item.title,120))+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-1",attrs:{"icon":"","small":"","to":{ name: 'academy-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Academy")])]),(_vm.$can('update', 'Academy'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-1",attrs:{"icon":"","small":"","to":{ name: 'academy-edit', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Academy")])]):_vm._e(),(_vm.$can('destroy', 'Academy'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":_vm.deleting,"loading":_vm.isDialogOpen},on:{"click":function($event){return _vm.selectAcademyToDelete(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Academy")])]):_vm._e()]}}],null,true)})],1),(_vm.$can('destroy', 'Academy'))?_c('academy-delete-dialog',{attrs:{"data":_vm.selectedAcademies,"deleting":_vm.deleting,"is-dialog-open":_vm.isDialogOpen},on:{"update:isDialogOpen":function($event){_vm.isDialogOpen=$event},"update:is-dialog-open":function($event){_vm.isDialogOpen=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }